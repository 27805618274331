<template>
  <div class="footer-container">
    <hr />
    <div class="container">
      <div class="row m-0">
        <div class="col">
          <div class="title">
            Contact US
          </div>
          <div>
            1000/7 โครงการเดอะมาสเตอร์ รีเฟลคซ์ชั่น @ บีทีเอสอ่อนนุช ถนน อ่อนนุช
          </div>
          <div>
            Mon-Sun: 10am - 7pm
          </div>
          <div>
            Line @: @revealingclinic
          </div>
          <div>
            tel. 062-936-6228
          </div>
          <div>
            Facebook messenger: m.me/revealingclinic
          </div>
          <div class="social">
            <a
              href="https://www.facebook.com/revealingclinic/"
              target="_black"
              class="d-flex align-items-center"
              ><i class="fab fa-facebook-square"></i
            ></a>
            <!-- intragram -->
            <a
              href="https://www.instagram.com/revealingclinic/"
              target="_black"
              class="d-flex align-items-center"
              ><i class="fab fa-instagram"></i
            ></a>
          </div>
        </div>
        <div class="col-12 col-md link">
          <div class="title">
            Quick Link
          </div>
          <router-link :to="{ name: 'Home' }">Home</router-link>
          <router-link
            :to="{ name: 'ServiceSub', params: { subname: 'surgery' } }"
            >Service</router-link
          >
          <router-link :to="{ name: 'Review' }">Review</router-link>
          <router-link :to="{ name: 'Blogs' }">Blogs</router-link>
          <router-link :to="{ name: 'Contact' }">Contact</router-link>
          <div
            data-token="WGlNVQ52WJALPvRDupgudNd3JV9woZn5Otc6txnGyYQ8uli4B8"
            class="romw-badge pt-3"
          ></div>
        </div>
        <div class="col">
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frevealingclinic%2F&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1400322780261711"
            style="border:none;overflow:hidden"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allow="encrypted-media"
            class="title"
          ></iframe>
        </div>
      </div>
      <div class="terms">
        Revealing © 2019. All rights reserved. Terms of use and Privacy Policy
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@scss/media";
@import "@scss/color";
.footer-container {
  margin-top: 10vh;
  background-color: white;
  border-top: 1px solid #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
}
hr {
  margin-top: 0px;
  width: 100%;
  height: 2px;
  background: $pink1;
  border: none;
}

.link {
  display: flex;
  flex-direction: column;
}

.col {
  display: flex;
  flex-direction: column;
}

a {
  color: $dark2;
  text-decoration: none;
  &:hover {
    color: $pink1;
  }
}

.title {
  margin-top: 5vh;
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 10px;
}
.social {
  display: flex;
  flex-direction: row;
  i {
    font-size: 30px;
    color: $pink1;
    padding: 10px;
    &:first-child {
      padding-left: 0;
    }
  }
}

iframe {
  width: 100%;
  min-height: 50vh;
}

.terms {
  margin-bottom: 10px;

  @include small {
    margin-top: 30px;
    font-size: 10px;
  }
}
</style>
